import logo_unam from "../../img/logo_unam.png";

function Menu(props) {

  const eleccionActiva = ((props.datosEleccion) ? props.datosEleccion[0]:"")
  return (
    <nav className="w-full bg-white shadow-md px-4 md:px-14 py-2 flex justify-around items-center">
      <div>
        <img className="w-24 md:w-32" src={logo_unam} />
      </div>
      <div>
        <h2 className="uppercase font-bold text-md text-sm md:text-2xl text-sky-600">
            {eleccionActiva.descripcion} {eleccionActiva.anio}
        </h2>
      </div>
    </nav>
  );
}

export default Menu;
