import React, { useState, useEffect } from "react";
import FormPadron from "./Components/Padron/FormPadron";
import Padron from "./Components/Padron/Padron";
import Card from "./Components/UI/Card";
import api from "./API/Padron";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactGA from "react-ga4";
import Footer from "./Components/Layout/Footer";
import Menu from "./Components/Layout/Menu";
import "./App.css";

function App() {
  ReactGA.send("pageview");

  const [datosVotante, setVotante] = useState(null);
  const [datosEleccion, setEleccion] = useState(null);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(false);
  const AlertaDocumento = withReactContent(Swal);

  const consultarEleccionActiva = ()=>{
    api
      .get("/elecciones?es_activa=es_igual_a;true")
      .then((response)=>{
        if (response.status === 200) {
          setEleccion(response.data);
        }
      })
      .catch((error) => {
        let tipoError = "error";
        let mensaje = "";
        let titulo = "Error";

        switch (error.response.status) {
          case 403:
            titulo = "El token es inválido!";
            tipoError = "warning";
            break;
          case 404:
            titulo = "No se encontró el recurso";
            break;
          default:
            titulo = "Oops";
            mensaje =
                "Lo sentimos, no pudimos completar tu solicitud, intenta de nuevo más tarde";
        }
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    consultarEleccionActiva();
  }, []);

  const consultarPadron = (tipoDocumento, nroDocumento) => {
    api
      .post("/electores", {
        token: token,
        tipo_documento: tipoDocumento,
        nro_documento: nroDocumento,
      })
      .then((response) => {
        if (response.status === 200) {
          setVotante(response.data);
          registrarEventoGA("Padron", "Consulta", "Consulta con resultado");
        } else if (response.status === 204) {
          AlertaDocumento.fire({
            title: "No hay coincidencias",
            text: "No se encontró a la persona con documento " + nroDocumento,
            icon: "info",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#58b055",
          });
          registrarEventoGA("Padron", "Consulta", "Consulta sin resultado");
        }

      })
      .catch((error) => {
        let tipoError = "error";
        let mensaje = "";
        let titulo = "Error";
        let motivos = "";
        switch (error.response.status) {
          case 400:
            let persona = error.response.data[0];
            titulo = `<p class=\"text-left text-xl text-blue-400 font-bold\">${persona.apellido}, ${persona.nombre} (${persona.tipo_documento} ${persona.nro_documento})</p>`;
            motivos = error.response.data.map(item => {
              const nombreCarrera = item.nombre_carrera ? `(${item.nombre_carrera})`:'';
              return `<li class="text-left text-base "><b>${item.nombre_claustro} ${nombreCarrera}</b>: ${item.motivo_no_vota} </li>`
            }).join("");
            mensaje = `<p class="text-left ">Usted no está habilitado para votar. <br>Los motivos según claustro son:</p><br><ul>${motivos}</ul>`;
            tipoError = "info";
            break;
          case 403:
            titulo = "El token es inválido!";
            tipoError = "warning";
            break;
          case 404:
            titulo = "No se encontró el recurso";
            break;
          case 422:
            titulo = "El tipo o el número de documento no es válido";
            tipoError = "warning";
            break;
          default:
            titulo = "Oops";
            mensaje =
              "Lo sentimos, no pudimos completar tu solicitud, intenta de nuevo más tarde";
        }

        AlertaDocumento.fire({
          title: titulo,
          html: mensaje,
          icon: tipoError,
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#58b055",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const documentoHandler = (tipoDocumento, nroDocumento) => {
    setLoading(true);
    consultarPadron(tipoDocumento, nroDocumento);
  };

  const tokenHandler = (token) => {
    setToken(token);
  };

  const cleanVotanteHandler = () => {
    setVotante(null);
    setLoading(false);
  };

  const registrarEventoGA = (categoria, accion, etiqueta) => {
    ReactGA.event({
      category: categoria,
      action: accion,
      label: etiqueta,
    });
  };

  const printHandler = () => {
    window.print();
  };

  let showContent = (
    <Card className=" bg-white shadow-md rounded p-8 mt-10">
      <FormPadron
        onSetDocumento={documentoHandler}
        onSetToken={tokenHandler}
        onCleanVotante={cleanVotanteHandler}
        isLoading={loading}
      />
    </Card>
  );

  if (datosVotante != null) {
    //Esto tiene que ser un componente
    showContent = (
      <Card className=" bg-white shadow-md rounded p-4 mt-10 m-8 text-lg">
        <Padron datosVotante={datosVotante} datosEleccion={datosEleccion}/>
        <div className="flex items-center justify-center">
          <button
            className="hidden lg:block print:hidden text-xl bg-green-700 hover:bg-green-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={printHandler}
          >
            Imprimir
          </button>
          <button
            className=" print:hidden text-xl  w-full md:w-fit lg:ml-4 bg-sky-600 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            onClick={cleanVotanteHandler}
          >
            Nueva búsqueda
          </button>
        </div>
      </Card>
    );
  }

  return (
    <div className="Main-container flex flex-col">
      <Menu datosEleccion={datosEleccion}/>
      <div className="flex justify-center items-center flex-col mb-14">
        {showContent}
      </div>
      <Footer/>
    </div>
  );
}

export default App;
