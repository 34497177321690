export const getSiglaDependencia = (idDependencia = 0) => {
    let sigla = '';

    switch(idDependencia) {
        case 10:
            sigla = 'RECTORADO';
            break;
        case 11:
            sigla = 'FAyD';
            break;
        case 12:
            sigla = 'FCE';
            break;
        case 13:
            sigla = 'FCEQyN';
            break;
        case 14:
            sigla = 'FCF';
            break;
        case 15:
            sigla = 'FHyCS';
            break;
        case 16:
            sigla = 'FIO';
            break;  
        case 18:
            sigla = 'EAE';
            break; 
        default:
            sigla = 'OTROS';
            break;
    }

    return sigla;
}

export const getDescripcionClaustro = (idClaustro) => {
    let claustro = 'Otros';
    
    switch(idClaustro){
        case 1:
            claustro = 'Docente';
            break;
        case 2:
            claustro = 'Nodocente';
            break;
        case 3:
            claustro = 'Estudiantil';
            break;
        case 4:
            claustro = 'Graduados';
            break;
        default:
            break;
    };

    return claustro;
  }
  

