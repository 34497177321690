import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white w-full text-center py-10 md:p-14 mt-auto">
      <h2 className="text-5xl md:text-6xl pb-2 ">Contacto</h2>
      <div className="text-md md:text-xl">
        <p className="pb-2">
          Ante cualquier consulta enviá un correo electrónico a 
          <strong> junta.electoral@unam.edu.ar</strong>
        </p>
        <p>Desarrollado por el departamento de sistemas - Rectorado UNaM </p>
      </div>
    </footer>
  );
};

export default Footer;
