import React from "react";

const TipoDocumento = (props) => {

  const tipoDocumentoHandler = (event) => {
    props.onSetTipoDocumento(event.target.value);
  }

  return (
    <div>
      <label
        className="block text-gray-700 text-sm font-bold mb-2 uppercase"
      >
        Tipo de documento
      </label>
      <div className="relative">
        <select
          className="text-2xl block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-1 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="grid-state"
          onChange={tipoDocumentoHandler}
        >
          <option value="DNI">DNI</option>
          <option value="CT">CUIT</option>
          <option value="CL">CUIL</option>
          <option value="PAS">Pasaporte</option>
          <option value="LC">Libreta Civica</option>
          <option value="CI">Cédula de Identidad</option>
          <option value="LE">Libreta de Enrolamiento</option>
          <option value="CD">Cédula Diplomática</option>
          <option value="CM">Cédula del Mercosur</option>
          <option value="CC">Cédula Ciudadana</option>
          <option value="CA">Carta de Identidad</option>
          <option value="DNT">Documento Nacional de Identidad Temporario</option>
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default TipoDocumento;
