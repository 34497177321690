import React from "react";

const VoteStyle = (props) => {
  let elemento = <span className="text-red-700 font-bold">NO</span>;

  if (props.vota) {
    elemento = <span className="text-green-700 font-bold">SI</span>;
  }

  return <>{elemento}</>;
};

export default VoteStyle;
