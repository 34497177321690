import React from "react";
import PadronCabecera from "./PadronCabecera";
import PadronDatos from "./PadronDatos";

const Padron = (props) => {
  let indice = 0;
  const id_tipo_eleccion = props.datosEleccion[0].id_tipo_eleccion;
  const padronDatos = props.datosVotante.detalle.map((datos, index) => {
    indice++;
    return (
      <PadronDatos
        className="py-4"
        key={indice}
        tipo_documento={props.datosVotante.tipo_documento}
        documento={props.datosVotante.nro_documento}
        apellido={props.datosVotante.apellido}
        nombre={props.datosVotante.nombre}
        id_tipo_eleccion={id_tipo_eleccion}
        claustro={datos.id_claustro}
        dependencia={datos.id_unidad_academica}
        orden={datos.orden ? datos.orden : " - "}
        mesa={datos.mesa ? datos.mesa.numero_mesa : " - "}
        votaRector={datos.vota_rector}
        votaDecano={datos.vota_ua}
        votaConsejoSuperior={datos.vota_cs}
        votaConsejoDirectivo={datos.vota_cd}
        ubicacion={datos.ubicacion.descripcion}
        localidad={datos.ubicacion.localidad}
        direccion={datos.ubicacion.direccion}
        total_filas={props.datosVotante.detalle.length}
        fila_actual={indice}
      />
    );
  });

  return (
    <div className="uppercase">
      <PadronCabecera
        documento={props.datosVotante.nro_documento}
        apellido={props.datosVotante.apellido}
        nombre={props.datosVotante.nombre}
      />
      <hr />
      {padronDatos}
    </div>
  );
};

export default Padron;
