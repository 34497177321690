import React, { useState, useRef } from "react";
import TipoDocumento from "./TipoDocumento";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { BiLoaderAlt } from "react-icons/bi";
import ReCAPTCHA from "react-google-recaptcha";

const FormPadron = (props) => {
  const [nroDocumento, setNroDocumento] = useState("");
  const [tipoDocumento, setTipoDocumento] = useState("DNI");
  const [inputTypeDocumento, setInputTypeDocumento] = useState("number");

  const AlertaDocumento = withReactContent(Swal);
  const captcha = useRef(null);

  const clearHandler = () => {
    setNroDocumento("");
    props.onCleanVotante(null);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    if (nroDocumento === "") {
      AlertaDocumento.fire({
        title: <p>Oops!</p>,
        text: "Debe ingresar un número de documento válido",
        icon: "warning",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#58b055",
      });
      return false;
    }

    if (captcha.current.getValue()) {
      props.onSetDocumento(tipoDocumento, nroDocumento);
      captcha.current.reset();
    } else {
      AlertaDocumento.fire({
        title: <p>Oops!</p>,
        text: "Se debe validar el captcha",
        icon: "warning",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#58b055",
      });

      return false;
    }
  };

  function onChange(value) {
    if (value != null) {
      props.onSetToken(value);
    }
  }

  const nroDocumentoHandler = (event) => {
    setNroDocumento(event.target.value);
  };

  const tipoDocumentoHandler = (tipoDocumento) => {
    setTipoDocumento(tipoDocumento);

    if (
      tipoDocumento === "DNI" ||
      tipoDocumento === "CT" ||
      tipoDocumento === "CL"
    ) {
      setInputTypeDocumento("number");
    } else {
      setInputTypeDocumento("text");
    }
  };

  let botonBorrar = null;

  if (nroDocumento.length > 0) {
    botonBorrar = (
      <button
        className="text-xl bg-red-500 hover:bg-red-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        type="button"
        onClick={clearHandler}
      >
        Borrar
      </button>
    );
  }

  return (
    <div>
      <h1 className="block text-gray-700 text-md font-bold mb-4 text-center uppercase">
        Padrón Electoral
        {window._env_.ES_PROVISORIO === "true" ? " Provisorio" : ""}
      </h1>
      <form onSubmit={submitHandler}>
        <div className="flex flex-col md:flex-col ">
          <TipoDocumento onSetTipoDocumento={tipoDocumentoHandler} />
          <br />
          <div className="mb-8 mt-4 md:mt-5">
            <label className="block text-gray-700 text-sm font-bold mb-2 uppercase">
              Número de Documento
            </label>
            <input
              className="text-2xl appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="nroDocumento"
              type={inputTypeDocumento}
              value={nroDocumento}
              onChange={nroDocumentoHandler}
              maxLength="20"
            />
          </div>
        </div>
        <div className="pb-4 flex justify-center">
          <ReCAPTCHA
            ref={captcha}
            sitekey={window._env_.RECAPTCHA_SITEKEY}
            onChange={onChange}
          />
        </div>
        <div className="flex items-center justify-center">
          {botonBorrar}
          <button
            className="text-xl ml-4 bg-sky-600 hover:bg-sky-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            {props.isLoading ? (
              <BiLoaderAlt className="inline-block text-2xl animate-spin" />
            ) : null}{" "}
            Consultar
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormPadron;
