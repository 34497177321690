import React from "react";
import {
  getSiglaDependencia,
  getDescripcionClaustro,
} from "../Util/DatosEstaticos";
import VoteStyle from "../UI/VoteStyle";

const PadronDatos = (props) => {
  const clases = " " + props.className;
  let labelSyle = "font-bold text-sky-700";
  let showDivisor = "";
  let seccionesAMostrar = [];
  let claustrosExcluidos = [];

  //TODO: Se tendria que mover a otro lugar, ya que es una especie de config.
  switch (props.id_tipo_eleccion){
    case 1: //Eleccion General
      seccionesAMostrar = ['A'];
      break;
    case 2: //Eleccion Estudiantil
      claustrosExcluidos = [1, 2]; //DOC y NODOC
      seccionesAMostrar = ['B'];
      break;
    default:
      break;
  }

  const esClaustroExcluido = claustrosExcluidos.includes(props.claustro);


  if (props.total_filas > 1 && props.fila_actual != props.total_filas) {
    labelSyle = "font-bold text-sky-900";
    showDivisor = (
      <hr className="mt-6 border-2 border-dashed border-slate-300" />
    );
  }
  return (
    <div className={clases}>
      <p>
        <span className={labelSyle}>Dependencia: </span>
        {getSiglaDependencia(props.dependencia)}
      </p>
      <p>
        <span className={labelSyle}>Claustro: </span>
        {getDescripcionClaustro(props.claustro)}
      </p>
        {!esClaustroExcluido && window._env_.ES_PROVISORIO === "false" &&
      <p>
        <span className={labelSyle}>Mesa Nro: </span>
        {props.mesa}
      </p>}
        {!esClaustroExcluido && window._env_.ES_PROVISORIO === "false" &&
      <p>
        <span className={labelSyle}>Orden: </span>
        {props.orden}
      </p>}

      <p>
        <span className={labelSyle}> Localidad:</span> {props.localidad}
      </p>
      <p>
        <span className={labelSyle}> Ubicación:</span> {props.ubicacion}
      </p>
      <p>
        <span className={labelSyle}> Dirección:</span> {props.direccion}
      </p>
      <br></br>

      {(esClaustroExcluido) &&
      <div className="bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md" role="alert">
        <div className="flex">
          <div className="py-1">
            <svg className="fill-current h-6 w-6 text-red-500 mr-4" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 20">
              <path
                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
            </svg>
          </div>
          <div>
            <p className="font-bold">ACLARACIÓN SOBRE LA PARTICIPACIÓN ELECTORAL </p>
            <p className="text-sm text-justify"><b>{props.nombre} {props.apellido} ({props.tipo_documento} {props.documento})</b> pertenece al <b>claustro {getDescripcionClaustro(props.claustro)}</b>. Según el artículo 123 del Estatuto de la Universidad Nacional de Misiones, <b>no se encuentra habilitado para votar</b> en estas elecciones debido al orden de preeminencia establecido. </p>
          </div>
        </div>
      </div>
      }
        {seccionesAMostrar.includes('A') &&  //Seccion A
        <div className="flex justify-center ">
            <table className="table-auto mb-4 w-full">
                <thead className="text-xs lg:text-lg">
                <tr>
                    <th
                        colSpan={4}
                        className="bg-sky-700 text-white border border-slate-300"
                    >
                        ¿Qué Voto?
                    </th>
                </tr>
                <tr className="bg-sky-100 text-gray-900">
                    <th className="border border-slate-300  md:p-2">Rector y <br></br>Vicerrector</th>
                    <th className="border border-slate-300  md:p-2">Consejo <br></br>Superior</th>
                    <th className="border border-slate-300  md:p-2">Decano y <br></br>Vicedecano</th>
                    <th className="border border-slate-300  md:p-2">Consejo <br></br>Directivo</th>
                </tr>
                </thead>
                <tbody className="text-center">
                <tr>
                    <td className="border border-slate-300">
                        <VoteStyle vota={props.votaRector}/>
                    </td>
                    <td className="border border-slate-300">
                        <VoteStyle vota={props.votaConsejoSuperior}/>
                    </td>
                    <td className="border border-slate-300">
                        <VoteStyle vota={props.votaDecano}/>
                    </td>
                    <td className="border border-slate-300">
                        <VoteStyle vota={props.votaConsejoDirectivo}/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        }
      {seccionesAMostrar.includes('B') && !esClaustroExcluido && //Seccion B
      <div className="flex justify-center ">
        <table className="table-auto mb-4 w-full">
          <thead className="text-xs lg:text-lg">
          <tr>
            <th
              colSpan={4}
              className="bg-sky-700 text-white border border-slate-300"
            >
              ¿Qué Voto?
            </th>
          </tr>
          <tr className="bg-sky-100 text-gray-900">
            <th className="border border-slate-300  md:p-2">Consejo <br></br>Superior</th>
            <th className="border border-slate-300  md:p-2">Consejo <br></br>Directivo</th>
          </tr>
          </thead>
          <tbody className="text-center">
          <tr>
            <td className="border border-slate-300">
              <VoteStyle vota={props.votaConsejoSuperior}/>
            </td>
            <td className="border border-slate-300">
              <VoteStyle vota={props.votaConsejoDirectivo}/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      }
      {showDivisor}
    </div>
  );
};

export default PadronDatos;
