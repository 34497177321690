import React from "react";

const PadronCabecera = (props) => {
  const clases = " " + props.className;

  return (
    <div className={clases}>
      <h2 className="text-center pb-4 text-xl md:text-2xl">
        <span className="font-bold text-sky-700">
          {props.apellido} {props.nombre} ({props.documento})
        </span>
      </h2>
    </div>
  );
};

export default PadronCabecera;
