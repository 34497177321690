import React from "react";

const Card = (props) => {
  const classes =
    " card-padron w-11/12 md:w-2/4 mx-8 rounded justify-between " +
    props.className;

  return <div className={classes}>{props.children}</div>;
};

export default Card;
